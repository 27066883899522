import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home_badkamersNl from "../views/Home_badkamersNl.vue";
import Bedankt_badkamersNl from "../views/Bedankt_badkamersNl.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   redirect: "/scootmobielen",
  // },
  // {
  //   path: "/scootmobielen",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/badkamers",
  //   name: "badkamers",
  //   component: Home_badkamersNl,
  // },
  // {
  //   path: "/badkamers/bedankt",
  //   name: "bedankt",
  //   component: Bedankt_badkamersNl,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
